import { Box, Paper, ThemeProvider, createTheme } from "@mui/material";
import Navbar from "./components/Navbar";
import AppRoutes from "@/routes";
import colors from "@/utils/colors";
import Footer from "@/components/Footer";
import "@fontsource/roboto";
import LogoTopHalf from "@/assets/gold-logo-top-half.png";
import LogoBottomHalf from "@/assets/gold-logo-bottom-half.png";
import styled, { keyframes } from "styled-components";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    text: {
      primary: colors.font,
      secondary: colors.button,
    },
  },
  typography: {
    fontFamily: "Nunito, sans-serif",
    fontWeightRegular: 500,
  },
});

const slideDown = keyframes`
  0% { transform: translateY(-100%); }
  14.3% { transform: translateY(-50%); }
  28.6% { transform: translateY(-55%); }
  42.8% { transform: translateY(-50%); }
  57.14% { transform: translateY(-51%); }
  62% { transform: translateY(-50%); }
  100% { transform: translateY(-50%); }
  `;

const slideUp = keyframes`
  0% { transform: translateY(100%); }
  14.3% { transform: translateY(50%); }
  28.6% { transform: translateY(55%); }
  42.8% { transform: translateY(50%); }
  57.14% { transform: translateY(51%); }
  62% { transform: translateY(50%); }
  100% { transform: translateY(49.8%); }
  `;

const TopHalf = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 999;
  background-color: ${colors.font};
  animation:
    ${slideDown} 1s ease-out forwards,
    disapear1 1s ease-in 2s forwards;
`;

const BottomHalf = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 999;
  background-color: ${colors.font};
  animation:
    ${slideUp} 1s ease-out forwards,
    disapear2 1s ease-in 2s forwards;
`;

const StyledNavbar = styled.div`
  position: relative;
  z-index: 998;
`;

const App = () => {
  const [animate, setAnimate] = useState(true);
  const location = useLocation();
  const animationUsedAlready = sessionStorage.getItem("animation") === "true";
  const isHome = location.pathname === "/";
  useEffect(() => {
    if (isHome && !animationUsedAlready) {
      sessionStorage.setItem("animation", "true");
    }
    setAnimate(isHome && !animationUsedAlready);
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={0} sx={{ backgroundColor: colors.primary }}>
        <Box
          fontFamily="Inter"
          fontWeight={400}
          lineHeight={1.5}
          boxSizing="border-box"
        >
          {animate && (
            <div>
              <TopHalf>
                <img
                  src={LogoTopHalf}
                  alt="Top part of the logo"
                  style={{
                    width: "300px",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    margin: "auto",
                  }}
                />
              </TopHalf>
              <BottomHalf>
                <img
                  src={LogoBottomHalf}
                  alt="Bottom part of the logo"
                  style={{
                    width: "300px",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    margin: "auto",
                  }}
                />
              </BottomHalf>
            </div>
          )}
          <header>
            {location.pathname === "/" ? (
              <StyledNavbar>
                <Navbar />
              </StyledNavbar>
            ) : (
              <Navbar />
            )}
          </header>
          <AppRoutes />
          <footer>
            <Footer />
          </footer>
        </Box>
      </Paper>
    </ThemeProvider>
  );
};
export default App;
