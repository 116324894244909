import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import colors from "@/utils/colors";

export default function ActionAreaCard({
  title,
  img,
  text,
}: {
  title: string;
  img: string;
  text: string;
}) {
  return (
    <Card
      sx={{ maxWidth: 345, borderRadius: "15px" }}
      classes={"card-animation"}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={img}
          alt="image"
          sx={{ backgroundColor: colors.secondary }}
        />
        <CardContent sx={{ backgroundColor: colors.secondary }}>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.primary">
            {text}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
