import { Box, Divider, Paper, useMediaQuery } from "@mui/material";
import { styled } from "styled-components";
import colors from "@/utils/colors";
import { Link, useLocation } from "react-router-dom";

const FooterContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: middle;
  box-sizing: border-box;
  padding: 1rem 1rem !important;
  width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  font-weight: 100;
  font-size: 14px;
  color: ${colors.font};
  @media (min-width: 600px) {
    max-width: 720px !important;
    width: 100% !important;
  }
  @media (min-width: 900px) {
    max-width: 1236px !important;
    width: 100% !important;
  }
`;

const Footer = () => {
  const location = useLocation();
  const isCoffee = location.pathname.indexOf("coffee") !== -1;
  const isSmallScreen = useMediaQuery("(max-width: 750px)");
  return (
    <>
      <main>
        <Paper
          sx={{
            minHeight: "6vh",
            backgroundColor: isCoffee ? colors.coffebg : colors.primary,
            position: "relative",
            width: "100%",
            bottom: 0,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <Divider
            sx={{
              backgroundColor: isCoffee ? colors.white : undefined,
              width: "95% !important",
              margin: "auto",
            }}
          />
          <FooterContainer sx={{ paddingTop: "10px !important" }}>
            <Box
              sx={{
                width: isSmallScreen ? "90%" : "70%",
                justifyContent: "space-between",
                textAlign: "center",
                display: "flex",
              }}
            >
              <Box color={isCoffee ? colors.white : colors.font}>
                copyright &copy; MM Group 2023
              </Box>
              <Link
                to={"/contact"}
                style={{
                  textDecoration: "none",
                  color: isCoffee ? colors.white : colors.font,
                }}
              >
                <Box> info@mm-group.info</Box>
              </Link>
            </Box>
          </FooterContainer>
        </Paper>
      </main>
    </>
  );
};

export default Footer;
