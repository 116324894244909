import PageSectionContainer from "@/styles/PageSectionContainer";
import ConstructionIcon from "@mui/icons-material/Construction";
import { Box, Typography, Paper } from "@mui/material";
import colors from "@/utils/colors";
import { ScrollToTop } from "@/utils/helpers";

const Shopping = () => {
  return (
    <>
      <main>
        <ScrollToTop />
        <Paper
          sx={{ minHeight: "80vh", backgroundColor: colors.primary }}
          elevation={0}
        >
          <PageSectionContainer className="dip-from-black">
            <Box
              sx={{ textAlign: "center", paddingTop: "6rem", display: "float" }}
            >
              <ConstructionIcon sx={{ fontSize: "200px" }}></ConstructionIcon>
              <Typography sx={{ fontSize: "100px", textAlign: "center" }}>
                SHOPPING COMING SOON...
              </Typography>
            </Box>
          </PageSectionContainer>
        </Paper>
      </main>
    </>
  );
};

export default Shopping;
