import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import colors from "@/utils/colors";
import MenuIcon from "@mui/icons-material/Menu";

export default function BasicMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation();
  const location = useLocation();
  const isCoffe = location.pathname.indexOf("coffee") !== -1;
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          background: isCoffe
            ? colors.coffenav + "!important"
            : colors.font + "!important",
        }}
      >
        <MenuIcon></MenuIcon>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {" "}
        <Link to="/" style={{ color: colors.font }}>
          <MenuItem onClick={handleClose}>{t("navbar.home")}</MenuItem>
        </Link>
        <Link to="/about" style={{ color: colors.font }}>
          <MenuItem onClick={handleClose}>{t("navbar.about")}</MenuItem>
        </Link>
        <Link to="/services" style={{ color: colors.font }}>
          <MenuItem onClick={handleClose}>{t("navbar.service")}</MenuItem>
        </Link>
        {/*
        <Link to="/shopping" style={{color: colors.font}}>
          <MenuItem onClick={handleClose}>{t("navbar.shopping")}</MenuItem>
        </Link>*/}
        <Link to="/logistics" style={{ color: colors.font }}>
          <MenuItem onClick={handleClose}>{t("navbar.logistics")}</MenuItem>
        </Link>
        <Link to="/customs" style={{ color: colors.font }}>
          <MenuItem onClick={handleClose}>{t("navbar.customs")}</MenuItem>
        </Link>
        <Link to="/freight" style={{ color: colors.font }}>
          <MenuItem onClick={handleClose}>{t("navbar.freight")}</MenuItem>
        </Link>
        {/* <Link to="/coffee" style={{color: colors.font}}>
          <MenuItem onClick={handleClose}>{t("navbar.coffee")}</MenuItem>
        </Link> */}
        <Link to="/pricelist" style={{ color: colors.font }}>
          <MenuItem onClick={handleClose}>{t("navbar.pricelist")}</MenuItem>
        </Link>
        {/*
        <Link underline="none" href="/production" sx={{color: colors.font}}>
          <MenuItem onClick={handleClose}>{t("navbar.production")}</MenuItem>
        </Link>
        <Link underline="none" href="/innovation" sx={{color: colors.font}}>
          <MenuItem onClick={handleClose}>{t("navbar.innovation")}</MenuItem>
      </Link>*/}
      </Menu>
    </div>
  );
}
