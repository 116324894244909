const colors = {
  primary: "#CFCFCF",
  secondary: "#ECECEC",
  font: "#123449",
  button: "#19579f",
  button_hover: "#3D89E1",
  alert: "#e8bb0a",
  coffebg: "#a57b57",
  coffenav: "#603f2f",
  coffefooter: "#fceedc", 
  //coffebg: "#2e2d33",

  cream: "#E7EBC5",
  battleshipgrey: "#989788",
  yellow: "#F7F052",
  orange: "#F28123",
  syracuse: "#D34E24",
  carmin: "#53131E",
  ebony: "#5A464C",
  transparent: "rgba(0, 0, 0, 0)",
  platinum: "#E0E0E0",
  white: "rgb(255,255,255)",
  black: "#000000",
  title: "#123449",
  platinumlight: "#F2F2F2",
  electricblue: "#8AF3FF",
  shamrockgreen: "#109648",
  mossgreen: "#758E4F",
  logobrown: "rgb(34, 31, 32)",
  dutchwhite: "#DCCCA3",
  pearl: "#E8DDC5",
  timberwolf: "#D9D4D5",
};

export default colors;
