import PageSectionContainer from "@/styles/PageSectionContainer";
import { Box, Typography, Button, Grid } from "@mui/material";
import colors from "@/utils/colors";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormSchema } from "@/features/contact-page/validations";
import { useNavigate } from "react-router-dom";
import { CustomInputField } from "@/styles/CustomInputField";
import styled from "styled-components";
import EmailIcon from "@mui/icons-material/Email";
import emailjs from "@emailjs/browser";

type FormSchemaType = yup.InferType<typeof FormSchema>;

export const StyledButton = styled(({ ...props }) => <Button {...props} />)`
  background-color: ${colors.button} !important;
  color: ${colors.white} !important;
  margin-top: 1.5rem !important;
  text-transform: none !important;
  font-size: 1.2rem !important;
  width: 250px;
  height: 60px;
  &:hover {
    background-color: ${colors.button_hover} !important;
  }
  @media (max-width: 900px) {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
`;

const ServiceID = "service_8vlvufa";
const TemplateID = "template_5nufq94";
const PublicKey = "VhD4Fd_0xQ67l_zGn";

const ContactForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FormSchema),
  });

  const onSubmit = (data: FormSchemaType) => {
    navigate("/contact/success");
    const form = document.getElementById("contact-form");
    emailjs.sendForm(ServiceID, TemplateID, form as HTMLFormElement, PublicKey);
  };
  return (
    <>
      <PageSectionContainer
        className="dip-from-black"
        display="flex"
        flexDirection="column"
        marginTop="4rem"
        justifyContent="center"
        alignItems="center"
      >
        <EmailIcon
          sx={{ fontSize: "8rem", color: colors.button, marginBottom: "2rem" }}
        />
        <Box
          sx={{
            background: colors.secondary,
            width: "80%",
            padding: "15px",
            borderRadius: "20px",
            border: `solid 1px ${colors.button}`,
          }}
        >
          <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  color={colors.font}
                  marginY="2rem"
                  align="center"
                >
                  {t("contact.heading")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("contact.name_instruction")}
                </Typography>
                <CustomInputField
                  error={errors?.name?.message}
                  placeholder={t("contact.name_placeholder")}
                  type="text"
                  {...register("name")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("contact.email_instruction")}
                </Typography>
                <CustomInputField
                  error={errors?.email?.message}
                  placeholder={t("contact.email_placeholder")}
                  type="text"
                  {...register("email")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("contact.phone_instruction")}
                </Typography>
                <CustomInputField
                  error={errors?.phone?.message}
                  placeholder={t("contact.phone_placeholder")}
                  type="text"
                  {...register("phone")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("contact.message_instruction")}
                </Typography>
                <CustomInputField
                  error={errors?.message?.message}
                  placeholder={t("contact.message_placeholder")}
                  type="text"
                  {...register("message")}
                />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <StyledButton type="submit" form="contact-form">
                  {t("contact.send")}
                </StyledButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </PageSectionContainer>
    </>
  );
};

export default ContactForm;
