import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";
import UpdateIcon from "@mui/icons-material/Update";
import StraightenIcon from "@mui/icons-material/Straighten";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BoltIcon from "@mui/icons-material/Bolt";
import DiscountIcon from "@mui/icons-material/Discount";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import WorkIcon from "@mui/icons-material/Work";
import colors from "@/utils/colors";
import PageSectionContainer from "@/styles/PageSectionContainer";
import FurtherAdvantages from "@/styles/FurtherAdvantages";
import ServiceOffer from "@/features/logistics-page/ServiceOffer";
import { ScrollToTop } from "@/utils/helpers";

export const StyledContainer = styled.div`
  animation: dipFromBlack 1.5s;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    background-color: ${colors.secondary};
    min-width: 100vw;
    top: 5%;
    height: 90%;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
`;

export const StyledPaper = styled(({ ...props }) => (
  <Paper elevation={2} {...props} />
))`
  animation: dipFromBlack 0.5s;

  background-color: ${colors.white};
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px !important;
  position: relative;
  z-index: 1;
  @media (min-width: 600px) {
    max-width: 720px;
  }
  @media (min-width: 900px) {
    max-width: 1236px;
  }
`;
export const StyledImage = styled.img`
  width: 350px;
  max-height: 300px;
  padding-top: 3rem;
`;

export const StyledButton = styled(({ ...props }) => <Button {...props} />)`
  background-color: ${colors.button} !important;
  color: ${colors.white} !important;
  margin-top: 1.5rem !important;
  text-transform: none !important;
  font-size: 1.2rem !important;
  width: 270px;
  height: 48px;
  &:hover {
    background-color: ${colors.button_hover} !important;
  }
  @media (max-width: 900px) {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
`;

const Logistics = () => {
  const { t } = useTranslation();
  const isMediumScreen = useMediaQuery("(min-width:900px)");

  return (
    <>
      <main>
        <ScrollToTop />
        <Paper
          sx={{
            minHeight: "100vh",
            backgroundColor: colors.primary,
            position: "relative",
            zIndex: "1",
          }}
          elevation={0}
        >
          <PageSectionContainer
            marginTop="3rem"
            marginBottom="3rem"
            position="relative"
          >
            <Grid container spacing={4} position="relative">
              <Grid
                item
                xs={12}
                md={7}
                display="flex"
                flexDirection="column"
                alignItems={isMediumScreen ? "left" : "center"}
                data-aos="fade-down"
              >
                <Typography
                  variant="subtitle1"
                  color={colors.title}
                  align={isMediumScreen ? "left" : "center"}
                  className="title-animation"
                  fontSize="50px !important"
                  lineHeight={1.4}
                >
                  {t("logistics.subheading")}
                </Typography>
                <Typography
                  maxWidth={isMediumScreen ? "80%" : "100%"}
                  variant="subtitle1"
                  color={colors.title}
                  align={isMediumScreen ? "left" : "center"}
                  className="title-animation"
                  fontSize="30px !important"
                  lineHeight={1.8}
                >
                  {t("logistics.description")}
                </Typography>
                <Link to="/contact">
                  <StyledButton variant="contained">
                    {t("logistics.contact_us")}
                  </StyledButton>
                </Link>
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box data-aos="fade-down">
                  <StyledImage
                    src="https://img.icons8.com/pastel-glyph/512/19579f/paper-plane--v3.png"
                    alt="paper-plane--v3"
                  />
                </Box>
              </Grid>
            </Grid>
          </PageSectionContainer>
          <StyledContainer>
            <StyledPaper>
              <Box marginY="3rem" position="relative">
                <Typography
                  paddingTop="2rem"
                  variant="body1"
                  gutterBottom
                  color={colors.title}
                  className="title-animation"
                  fontSize="15px !important"
                >
                  {t("logistics.heading")}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={colors.title}
                  className="title-animation"
                  fontSize="40px !important"
                >
                  {t("logistics.what_we_offer")}
                </Typography>
              </Box>
              <Box position="relative">
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    data-aos="fade-up"
                    data-aos-delay={50}
                    data-aos-offset="100"
                    data-aos-duration="600"
                  >
                    <ServiceOffer
                      heading={t("logistics.on_hold_delivery")}
                      description={t("logistics.on_hold_delivery_desc")}
                      Icon={UpdateIcon}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    data-aos="fade-up"
                    data-aos-delay={100}
                    data-aos-offset="100"
                    data-aos-duration="600"
                  >
                    <ServiceOffer
                      heading={t("logistics.fulfillment")}
                      description={t("logistics.fulfillment_desc")}
                      Icon={CheckCircleOutlineIcon}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    data-aos="fade-up"
                    data-aos-delay={150}
                    data-aos-offset="100"
                    data-aos-duration="600"
                  >
                    <ServiceOffer
                      heading={t("logistics.logistics_solutions")}
                      description={t("logistics.logistics_solutions_desc")}
                      Icon={StraightenIcon}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    data-aos="fade-up"
                    data-aos-delay={200}
                    data-aos-offset="100"
                    data-aos-duration="600"
                  >
                    <ServiceOffer
                      heading={t("logistics.fast_delivery")}
                      description={t("logistics.fast_delivery_desc")}
                      Icon={BoltIcon}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    data-aos="fade-up"
                    data-aos-delay={250}
                    data-aos-offset="100"
                    data-aos-duration="600"
                  >
                    <ServiceOffer
                      heading={t("logistics.warehouse_logistics")}
                      description={t("logistics.warehouse_logistics_desc")}
                      Icon={WarehouseIcon}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    data-aos="fade-up"
                    data-aos-delay={300}
                    data-aos-offset="100"
                    data-aos-duration="600"
                  >
                    <ServiceOffer
                      heading={t("logistics.professionalism")}
                      description={t("logistics.professionalism_desc")}
                      Icon={WorkIcon}
                    />
                  </Grid>
                </Grid>
              </Box>
            </StyledPaper>
          </StyledContainer>
          <PageSectionContainer
            justifyContent="center"
            alignItems={"center"}
            marginBottom="1rem"
            overflow="hidden"
          >
            <Grid container spacing={10} overflow="hidden">
              <Grid
                item
                md={5}
                sm={12}
                display="flex"
                alignItems="center"
                data-aos="fade-right"
              >
                <Box
                  paddingTop="2rem"
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="h3"
                    color={colors.font}
                    gutterBottom
                    align="center"
                  >
                    {t("logistics.offer_heading_p1")}
                    {<span style={{ color: "#D34E24" }}>15%</span>}
                    {t("logistics.offer_heading_p2")}
                  </Typography>
                  <Typography
                    align="center"
                    variant="h6"
                    color={colors.font}
                    marginBottom="2rem"
                  >
                    {t("logistics.offer_subheading")}
                  </Typography>
                  <DiscountIcon
                    sx={{ color: colors.syracuse, fontSize: "6rem" }}
                  />
                </Box>
              </Grid>
              <Grid item md={7} sm={12}>
                <Box data-aos="fade-left" data-aos-delay={50}>
                  <FurtherAdvantages
                    heading={t("logistics.offer_point1")}
                    description={t("logistics.offer_point1_desc")}
                  />
                </Box>
                <Box data-aos="fade-left" data-aos-delay={100}>
                  <FurtherAdvantages
                    heading={t("logistics.offer_point2")}
                    description={t("logistics.offer_point2_desc")}
                  />
                </Box>
                <Box data-aos="fade-left" data-aos-delay={150}>
                  <FurtherAdvantages
                    heading={t("logistics.offer_point3")}
                    description={t("logistics.offer_point3_desc")}
                  />
                </Box>
                <Box data-aos="fade-left" data-aos-delay={200}>
                  <FurtherAdvantages
                    heading={t("logistics.offer_point4")}
                    description={t("logistics.offer_point4_desc")}
                  />
                </Box>
                <Box data-aos="fade-left" data-aos-delay={250}>
                  <FurtherAdvantages
                    heading={t("logistics.offer_point5")}
                    description={t("logistics.offer_point5_desc")}
                  />
                </Box>
                <Box data-aos="fade-left" data-aos-delay={300}>
                  <FurtherAdvantages
                    heading={t("logistics.offer_point6")}
                    description={t("logistics.offer_point6_desc")}
                  />
                </Box>
              </Grid>
            </Grid>
          </PageSectionContainer>
        </Paper>
      </main>
    </>
  );
};

export default Logistics;
