import colors from "@/utils/colors";
import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

const Container = styled(({ ...props }) => <Box {...props} />)`
  border: 2px solid ${colors.secondary};
  border-radius: 10px;
  width: 100% !important;
  margin: 1rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  box-sizing: border-box;
`;

const FurtherAdvantages = ({
  heading,
  description,
}: {
  heading: string;
  description: string;
}) => {
  return (
    <Container>
      <RadioButtonCheckedIcon sx={{ color: colors.button, fontSize: "3rem" }} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="left"
        justifyItems="center"
        marginLeft="2rem"
      >
        <Typography variant="h4" fontSize="1.5rem" color={colors.button}>
          {heading}
        </Typography>
        <Typography variant="subtitle2" color={colors.font}>
          {description}
        </Typography>
      </Box>
    </Container>
  );
};

export default FurtherAdvantages;
