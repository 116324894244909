import PageSectionContainer from "@/styles/PageSectionContainer";
import { Box, Typography, Paper, TextField, Button } from "@mui/material";
import colors from "@/utils/colors";
import { useTranslation } from "react-i18next";
import ContactForm from "@/features/contact-page/ContactForm";
import { ScrollToTop } from "@/utils/helpers";

const Coffee = () => {
  const { t } = useTranslation();
  return (
    <>
      <main>
        <ScrollToTop />
        <Paper
          sx={{
            minHeight: "80vh",
            backgroundColor: colors.primary,
          }}
          elevation={0}
        >
          <ContactForm />
        </Paper>
      </main>
    </>
  );
};

export default Coffee;
