import PageSectionContainer from "@/styles/PageSectionContainer";
import colors from "@/utils/colors";
import {
  Box,
  Grid,
  Link,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import FlightOutlinedIcon from "@mui/icons-material/FlightOutlined";
import DirectionsTransitFilledIcon from "@mui/icons-material/DirectionsTransitFilled";
import RailFreightImg from "@/assets/rail-freight-img.webp";
import AirFreightImg from "@/assets/air-freight-img.webp";
import SeaFreightImg from "@/assets/sea-freight-img.webp";
import { useEffect, useState } from "react";
import SeaFreight from "@/features/freight-page/SeaFreight";
import AirFreight from "@/features/freight-page/AirFreight";
import LandFreight from "@/features/freight-page/LandFreight";

const FreightSummaryContainer = styled(Box)`
  width: 100%;
  height: 100%;
  padding: 1rem 4rem 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const FreightSummaryTitle = styled.p`
  color: ${colors.syracuse};
  font-size: 1rem;
  font-weight: 700;
`;

const FreightSummaryDescription = styled.p`
  color: gray;
  font-size: 12px;
  font-weight: 400;
`;

const CoverImageContainer = styled.div`
  position: absolute;
  top: -40%;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 2;
`;

const CoverImage = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  transition: left 2s;
  opacity: 60%;
  object-fit: cover;
`;

const images = [RailFreightImg, AirFreightImg, SeaFreightImg];

const Freight = () => {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 10000);
    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <main>
        <Paper
          sx={{ minHeight: "94vh", backgroundColor: colors.primary, zIndex: 1 }}
          elevation={0}
        >
          <CoverImageContainer>
            {images.map((image, index) => (
              <CoverImage
                key={index}
                src={image}
                style={{
                  transform: `translateX(${(index - currentImage) * 100}%)`,
                  transition: "ease-in-out 3s",
                }}
              />
            ))}
          </CoverImageContainer>
          <Box
            paddingBottom="2rem"
            marginTop="4rem"
            position="relative"
            zIndex={3}
          >
            <PageSectionContainer minHeight="250px" marginBottom="3rem">
              <Box width="100%" textAlign="center">
                <h1
                  style={{
                    fontWeight: 700,
                    color: "white",
                    fontSize: "4.5rem",
                    marginBottom: "8px",
                    marginTop: isSmallScreen ? 0 : "9rem",
                  }}
                >
                  {t("freight.heading")}
                </h1>
                <p
                  style={{
                    fontWeight: 400,
                    color: "white",
                    fontSize: "2rem",
                    marginTop: "8px",
                  }}
                >
                  {t("freight.subheading")}
                </p>
              </Box>
              <Paper
                elevation={0}
                sx={{
                  background: colors.secondary,
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                <Grid container spacing={0} padding="1rem">
                  <Grid item xs={12} md={4}>
                    <FreightSummaryContainer>
                      <Link
                        href="#sea-freight-section"
                        underline="none"
                        color="inherit"
                        sx={{
                          "&:hover": {
                            textDecoration: "none",
                          },
                        }}
                      >
                        <DirectionsBoatIcon
                          sx={{
                            fontSize: "50px !important",
                            transition: "color 0.5s",
                            "&:hover": {
                              color: colors.syracuse,
                            },
                          }}
                        />
                      </Link>
                      <FreightSummaryTitle>
                        {t("freight.sea_title")}
                      </FreightSummaryTitle>
                      <FreightSummaryDescription>
                        {t("freight.sea_description")}
                      </FreightSummaryDescription>
                    </FreightSummaryContainer>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FreightSummaryContainer>
                      <Link
                        href="#air-freight-section"
                        underline="none"
                        color="inherit"
                        sx={{
                          "&:hover": {
                            textDecoration: "none",
                          },
                        }}
                      >
                        <FlightOutlinedIcon
                          sx={{
                            fontSize: "50px !important",
                            transition: "color 0.5s",
                            "&:hover": {
                              color: colors.syracuse,
                            },
                          }}
                        />
                      </Link>
                      <FreightSummaryTitle>
                        {t("freight.air_title")}
                      </FreightSummaryTitle>
                      <FreightSummaryDescription>
                        {t("freight.air_description")}
                      </FreightSummaryDescription>
                    </FreightSummaryContainer>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FreightSummaryContainer>
                      <Link
                        href="#land-freight-section"
                        underline="none"
                        color="inherit"
                        sx={{
                          "&:hover": {
                            textDecoration: "none",
                          },
                        }}
                      >
                        <DirectionsTransitFilledIcon
                          sx={{
                            fontSize: "50px !important",
                            transition: "color 0.5s",
                            "&:hover": {
                              color: colors.syracuse,
                            },
                          }}
                        />
                      </Link>
                      <FreightSummaryTitle>
                        {t("freight.land_title")}
                      </FreightSummaryTitle>
                      <FreightSummaryDescription>
                        {t("freight.land_description")}
                      </FreightSummaryDescription>
                    </FreightSummaryContainer>
                  </Grid>
                </Grid>
              </Paper>
            </PageSectionContainer>
            <SeaFreight />
            <AirFreight />
            <LandFreight />
          </Box>
        </Paper>
      </main>
    </>
  );
};

export default Freight;
