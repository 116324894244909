import PageSectionContainer from "@/styles/PageSectionContainer";
import colors from "@/utils/colors";
import { Box, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import WhoWeAreImg from "@/assets/who-we-are-img.jpg";
import WhyUsImg from "@/assets/why-us-img.jpg";
import OurGoalsImg from "@/assets/our-goals-img.jpg";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  const isMediumScreen = useMediaQuery("(min-width:900px)");
  console.log(isMediumScreen);
  return (
    <>
      <main>
        <Paper
          sx={{ minHeight: "94vh", backgroundColor: colors.primary }}
          elevation={0}
        >
          <Box paddingBottom="2rem" marginTop="4rem">
            <PageSectionContainer minHeight="250px" className="dip-from-black">
              <Grid container spacing={2}>
                <Grid
                  item
                  md={6}
                  sm={12}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    boxSizing="border-box"
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <img
                      src={WhoWeAreImg}
                      alt="Who we are"
                      style={{ maxWidth: "80%", borderRadius: "10px" }}
                    />
                  </Box>
                </Grid>
                <Grid item md={6} sm={12} display="flex" alignItems="center">
                  <Box
                    padding="1rem"
                    borderRadius="10px"
                    marginTop={isMediumScreen ? 0 : "3rem"}
                    marginRight={isMediumScreen ? "3rem" : "0"}
                    sx={{ backgroundColor: colors.secondary }}
                  >
                    <Typography variant="h3">{t("about.who")}</Typography>
                    <Box padding="1rem 1rem 1rem 0">
                      <Typography variant="body1">
                        {t("about.who_desc")}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </PageSectionContainer>
            <PageSectionContainer minHeight="250px" className="dip-from-black">
              <Box
                borderRadius="10px"
                sx={{ backgroundColor: colors.secondary }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    md={6}
                    sm={12}
                    style={{ paddingTop: 0, order: isMediumScreen ? 2 : 1 }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      boxSizing="border-box"
                      width="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      paddingY="2rem"
                    >
                      <img
                        src={WhyUsImg}
                        alt="Why us"
                        style={{ maxWidth: "80%", borderRadius: "10px" }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sm={12}
                    style={{ order: isMediumScreen ? 1 : 2 }}
                  >
                    <Box padding="1rem 1rem 1rem 3rem">
                      <Typography variant="h3">{t("about.why")}</Typography>
                      <Box padding="1rem 1rem 1rem 0">
                        <Typography variant="body1">
                          {t("about.why_desc")}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </PageSectionContainer>
            <PageSectionContainer minHeight="250px" className="dip-from-black">
              <Grid container spacing={2}>
                <Grid
                  item
                  md={6}
                  sm={12}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    boxSizing="border-box"
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <img
                      src={OurGoalsImg}
                      alt="Our goals"
                      style={{ maxWidth: "80%", borderRadius: "10px" }}
                    />
                  </Box>
                </Grid>
                <Grid item md={6} sm={12} display="flex" alignItems="center">
                  <Box
                    padding="1rem"
                    borderRadius="10px"
                    marginTop={isMediumScreen ? 0 : "3rem"}
                    marginRight={isMediumScreen ? "3rem" : "0"}
                    sx={{ backgroundColor: colors.secondary }}
                  >
                    <Box padding="1rem">
                      <Typography variant="h3">{t("about.goal")}</Typography>
                      <Box padding="1rem 1rem 1rem 0">
                        <Typography variant="body1">
                          {t("about.goal_desc")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </PageSectionContainer>
          </Box>
        </Paper>
      </main>
    </>
  );
};

export default About;
