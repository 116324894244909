import { Routes, Route } from "react-router-dom";
import Homepage from "../components/Homepage";
import About from "../components/About";
import Service from "@/components/Service";
import Shopping from "@/components/Shopping";
import Logistics from "@/components/Logistics";
import Production from "@/components/Production";
import Innovation from "@/components/Innovation";
import Contact from "@/components/Contact";
import ContactSuccess from "@/components/ContactSuccess";
import Customs from "@/components/Customs";
import Pricelist from "@/components/Pricelist";
import Freight from "@/components/Freight";

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Homepage />} />
    <Route path="/about" element={<About />} />
    <Route path="/services" element={<Service />} />
    <Route path="/shopping" element={<Shopping />} />
    <Route path="/logistics" element={<Logistics />} />
    <Route path="/customs" element={<Customs />} />
    {/* <Route path="/coffee" element={<Coffee />} /> */}
    <Route path="/production" element={<Production />} />
    <Route path="/innovation" element={<Innovation />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/contact/success" element={<ContactSuccess />} />
    <Route path="/pricelist" element={<Pricelist />} />
    <Route path="/freight" element={<Freight />} />
  </Routes>
);

export default AppRoutes;
