import { Box, Typography, Paper, Grid } from "@mui/material";
import colors from "@/utils/colors";
import Card from "@/features/homepage/Card";
import ContactImage from "@/assets/contact-us-img.jpg";
import AboutUsImage from "@/assets/about-us-img.jpg";
import LagerImage from "@/assets/lager-img.jpg";
import PartnersImage from "@/assets/lager-img.jpg";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import PageSectionContainer from "@/styles/PageSectionContainer";
import { useTranslation } from "react-i18next";
import { ScrollToTop } from "@/utils/helpers";

const SectionContainer = styled(Box)`
  box-sizing: border-box !important;
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  @media (min-width: 600px) {
    max-width: 720px;
  }
  @media (min-width: 900px) {
    max-width: 1236px;
  }
`;
const SectionContainerImages = styled(SectionContainer)`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

const Homepage = () => {
  const { t } = useTranslation();

  return (
    <>
      <main>
        <ScrollToTop />
        <Paper
          sx={{ minHeight: "83.5vh", backgroundColor: colors.primary }}
          elevation={0}
        >
          <PageSectionContainer>
            <SectionContainer>
              <Typography
                variant="body1"
                color={colors.title}
                className={"title-animation smaller-text"}
              >
                {t("home.welcome")}
              </Typography>
            </SectionContainer>
            <SectionContainerImages>
              <Grid container spacing={4} justifyContent="center">
                <Grid item className={"card-animation1"}>
                  <Link to="/about">
                    <Card
                      title={t("home.more")}
                      img={AboutUsImage}
                      text={t("home.discover")}
                    />
                  </Link>
                </Grid>
                <Grid item className={"card-animation2"}>
                  <Link to="/services">
                    <Card
                      title={t("home.explore")}
                      img={LagerImage}
                      text={t("home.services")}
                    />
                  </Link>
                </Grid>
                {/*
                <Grid item className={"card-animation3"}>
                  <Link to="/contact">
                    <Card
                      title={t("home.parthners")}
                      img={ParthnersImage}
                      text={t("home.aboutparthners")}
                    />
                  </Link>
                </Grid>*/}
                <Grid item className={"card-animation3"}>
                  <Link to="/contact">
                    <Card
                      title={t("home.contact")}
                      img={ContactImage}
                      text={t("home.assistance")}
                    />
                  </Link>
                </Grid>
              </Grid>
            </SectionContainerImages>
          </PageSectionContainer>
        </Paper>
      </main>
    </>
  );
};

export default Homepage;
