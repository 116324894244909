import { Box, Typography, Paper, Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";
import colors from "@/utils/colors";
import PageSectionContainer from "@/styles/PageSectionContainer";
import { styled } from "styled-components";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CoffeeIcon from "@mui/icons-material/Coffee";
import FlightOutlinedIcon from "@mui/icons-material/FlightOutlined";
import DirectionsTransitFilledIcon from "@mui/icons-material/DirectionsTransitFilled";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import { useTranslation } from "react-i18next";
import { ScrollToTop } from "@/utils/helpers";

const SectionContainer = styled(Box)`
  box-sizing: border-box !important;
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  @media (min-width: 600px) {
    max-width: 720px;
  }
  @media (min-width: 900px) {
    max-width: 1236px;
  }
`;

const Card = styled(Box)`
  background: ${colors.secondary};
  border-radius: 15px;
  padding: 20px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Service = () => {
  const { t } = useTranslation();
  return (
    <>
      <main>
        <ScrollToTop />
        <Paper
          sx={{ minHeight: "83.5vh", backgroundColor: colors.primary }}
          elevation={0}
        >
          <PageSectionContainer className="dip-from-black">
            <SectionContainer sx={{ marginTop: "0 !important" }}>
              <Typography
                variant="body1"
                color={colors.title}
                className={"title-animation"}
                sx={{ fontSize: "40px !important" }}
              >
                {t("services.heading")}
              </Typography>
              <Grid container spacing={4} sx={{ paddingTop: "4rem" }}>
                <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
                  <Card>
                    <LocalShippingIcon
                      sx={{
                        fontSize: "120px !important",
                        paddingBottom: "1rem",
                      }}
                    ></LocalShippingIcon>
                    <Typography
                      variant="h4"
                      sx={{ fontWeight: "700 !important" }}
                    >
                      {t("services.logistics")}
                    </Typography>
                    <br></br>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      paddingBottom="1rem"
                      flex="1"
                    >
                      <Typography sx={{ paddingBottom: "1rem" }}>
                        {t("services.explore_logistics")} <br />{" "}
                      </Typography>
                      <Link to="/logistics">
                        <Button
                          variant="contained"
                          sx={{
                            background: colors.button,
                            color: colors.white,
                            width: "50%",
                          }}
                        >
                          {t("services.learn_more")}
                        </Button>
                      </Link>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
                  <Card>
                    <ReceiptLongIcon
                      sx={{
                        fontSize: "120px !important",
                        paddingBottom: "1rem",
                      }}
                    ></ReceiptLongIcon>
                    <Typography
                      variant="h4"
                      sx={{ fontWeight: "700 !important" }}
                    >
                      {t("services.customs")}
                    </Typography>
                    <br></br>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      paddingBottom="1rem"
                      flex="1"
                    >
                      <Typography sx={{ paddingBottom: "1rem" }}>
                        {t("services.explore_customs")} <br />
                      </Typography>
                      <Link to="/customs">
                        <Button
                          variant="contained"
                          sx={{
                            background: colors.button,
                            color: colors.white,
                            width: "50%",
                          }}
                        >
                          {t("services.learn_more")}
                        </Button>
                      </Link>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
                  <Card>
                    <Box
                      boxSizing="border-box"
                      display="flex"
                      width="100%"
                      justifyContent="space-between"
                      height="120px"
                      alignItems="center"
                      marginBottom="1rem"
                      paddingLeft="10%"
                      paddingRight="10%"
                      paddingTop="1rem"
                    >
                      <DirectionsBoatIcon
                        sx={{
                          fontSize: "80px !important",
                          paddingBottom: "1rem",
                        }}
                      />
                      <DirectionsTransitFilledIcon
                        sx={{
                          fontSize: "80px !important",
                          paddingBottom: "1rem",
                        }}
                      />
                      <FlightOutlinedIcon
                        sx={{
                          fontSize: "80px !important",
                          paddingBottom: "1rem",
                        }}
                      />
                    </Box>
                    <Typography
                      variant="h4"
                      sx={{ fontWeight: "700 !important" }}
                    >
                      {t("services.freight")}
                    </Typography>
                    <br></br>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      paddingBottom="1rem"
                      flex="1"
                    >
                      <Typography sx={{ paddingBottom: "1rem" }}>
                        {t("services.explore_freight")} <br />
                      </Typography>
                      <Link to="/freight">
                        <Button
                          variant="contained"
                          sx={{
                            background: colors.button,
                            color: colors.white,
                            width: "50%",
                          }}
                        >
                          {t("services.learn_more")}
                        </Button>
                      </Link>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </SectionContainer>
          </PageSectionContainer>
        </Paper>
      </main>
    </>
  );
};

export default Service;
