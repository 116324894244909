import { StyledButton } from "@/features/contact-page/ContactForm";
import colors from "@/utils/colors";
import { ScrollToTop } from "@/utils/helpers";
import { Box, Link, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const Container = styled(Box)`
  padding: 2rem 1rem;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 600px !important;

  @media (min-width: 600px) {
    padding: 3rem 1rem;
  }

  @media (min-width: 900px) {
    padding: 4rem 1rem;
  }
`;

const ContactSuccess = () => {
  const { t } = useTranslation();
  return (
    <>
      <main>
        <ScrollToTop />
        <Paper
          sx={{
            minHeight: "80vh",
            backgroundColor: colors.primary,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Container>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box marginTop="1rem">
                <img
                  width="150"
                  height="150"
                  src="https://img.icons8.com/pastel-glyph/512/19579f/checked--v1.png"
                  alt="checked--v1"
                />
              </Box>
              <Typography
                variant="h4"
                color={colors.font}
                fontWeight={500}
                marginY="1rem"
                align="center"
              >
                {t("contact_success.heading")}
              </Typography>
              <Typography variant="body1" color={colors.font} align="center">
                {t("contact_success.text_1")}
                <br />

                {t("contact_success.text_2")}
                <br />
                <br />
                {t("contact_success.text_3")}
              </Typography>
              <Link marginTop="3rem" underline="none" href="/">
                <StyledButton sx={{ height: "48px", width: "180px" }}>
                  {t("contact_success.button")}
                </StyledButton>
              </Link>
            </Box>
          </Container>
        </Paper>
      </main>
    </>
  );
};

export default ContactSuccess;
