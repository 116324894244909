import PageSectionContainer from "@/styles/PageSectionContainer";
import { Box, Grid, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import DoneIcon from "@mui/icons-material/Done";
import colors from "@/utils/colors";
import SeaFreightSectionImage from "@/assets/sea-freight-section-img.webp";

const Title = styled.h3`
  font-size: 1.5rem;
`;

const DoneIconStyled = styled(DoneIcon)`
  font-size: 14px !important;
  color: ${colors.syracuse};
  margin-right: 1rem;
`;

const Description = styled.p`
  font-size: 1rem;
`;

const CardTitle = styled.h4`
  font-size: 1rem;
  font-weight: 700;
`;
const CardPoints = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const SectionImageContainer = styled.div`
  width: 100%;
`;

const SectionImage = styled.img`
  object-fit: cover;
  height: 150px;
  width: 100%;
`;

const SeaFreight = () => {
  const { t } = useTranslation();
  return (
    <PageSectionContainer
      id="sea-freight-section"
      className="navbar-placeholder-before"
      marginTop={0}
    >
      <SectionImageContainer>
        <SectionImage
          src={SeaFreightSectionImage}
          alt="Image depicting a cargo ship"
        />
      </SectionImageContainer>
      <Title>{t("freight.sea_subtitle")}</Title>
      <Description>{t("freight.sea_selling_point")}</Description>
      <Grid container spacing={4} marginY="1rem">
        <Grid
          item
          xs={12}
          md={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Paper
            elevation={1}
            sx={{
              width: "300px",
              minHeight: "230px",
              textAlign: "center",
            }}
          >
            <CardTitle>{t("freight.sea_fcl")}</CardTitle>
            <Box textAlign="left" padding="0rem 1rem">
              <CardPoints>
                <DoneIconStyled />
                {t("freight.sea_fcl_point_1")}
              </CardPoints>
              <CardPoints>
                <DoneIconStyled />
                {t("freight.sea_fcl_point_2")}
              </CardPoints>
              <CardPoints>
                <DoneIconStyled />
                {t("freight.sea_fcl_point_3")}
              </CardPoints>
            </Box>
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Paper
            elevation={1}
            sx={{
              width: "300px",
              height: "230px",
              textAlign: "center",
            }}
          >
            <CardTitle>{t("freight.sea_lcl")}</CardTitle>
            <Box textAlign="left" padding="0rem 1rem">
              <CardPoints>
                <DoneIconStyled />
                {t("freight.sea_lcl_point_1")}
              </CardPoints>
              <CardPoints>
                <DoneIconStyled />
                {t("freight.sea_lcl_point_2")}
              </CardPoints>
            </Box>
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Paper
            elevation={1}
            sx={{
              width: "300px",
              height: "230px",
              textAlign: "center",
            }}
          >
            <CardTitle>{t("freight.sea_services")}</CardTitle>
            <Box textAlign="left" padding="0rem 1rem">
              <CardPoints>
                <DoneIconStyled />
                {t("freight.sea_services_point_1")}
              </CardPoints>
              <CardPoints>
                <DoneIconStyled />
                {t("freight.sea_services_point_2")}
              </CardPoints>
              <CardPoints>
                <DoneIconStyled />
                {t("freight.sea_services_point_3")}
              </CardPoints>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Title>{t("freight.sea_partner_heading")}</Title>
      <Description>{t("freight.sea_partner_description_part_1")}</Description>
      <Description>{t("freight.sea_partner_description_part_2")}</Description>
      <Description>{t("freight.sea_partner_description_part_3")}</Description>
    </PageSectionContainer>
  );
};

export default SeaFreight;
