import PageSectionContainer from "@/styles/PageSectionContainer";
import colors from "@/utils/colors";
import { Box, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  StyledButton,
  StyledContainer,
  StyledImage,
  StyledPaper,
} from "./Logistics";
import ServiceOffer from "@/features/logistics-page/ServiceOffer";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Description } from "@mui/icons-material";
import CustomsLocation from "@/assets/biedermannsdorf-img.png";
import { ScrollToTop } from "@/utils/helpers";

const CustomsNew = () => {
  const { t } = useTranslation();
  const isMediumScreen = useMediaQuery("(min-width:900px)");

  return (
    <>
      <main>
        <ScrollToTop />
        <Paper
          sx={{
            minHeight: "100vh",
            backgroundColor: colors.primary,
            position: "relative",
            zIndex: "1",
          }}
          elevation={0}
        >
          <PageSectionContainer
            marginTop="3rem"
            marginBottom="3rem"
            position="relative"
          >
            <Grid container spacing={4} position="relative">
              <Grid
                item
                xs={12}
                md={7}
                display="flex"
                flexDirection="column"
                alignItems={isMediumScreen ? "left" : "center"}
                data-aos="fade-down"
              >
                <Typography
                  variant="subtitle1"
                  color={colors.title}
                  align={isMediumScreen ? "left" : "center"}
                  className="title-animation"
                  fontSize="50px !important"
                  lineHeight={1.4}
                >
                  {t("customs.subheading")}
                </Typography>
                <Typography
                  maxWidth={isMediumScreen ? "80%" : "100%"}
                  variant="subtitle1"
                  color={colors.title}
                  align={isMediumScreen ? "left" : "center"}
                  className="title-animation"
                  fontSize="30px !important"
                  lineHeight={1.8}
                >
                  {t("customs.description")}
                </Typography>
                <Link to="/contact">
                  <StyledButton variant="contained">
                    {t("customs.contact_us")}
                  </StyledButton>
                </Link>
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box data-aos="fade-down">
                  <StyledImage
                    src="https://img.icons8.com/ios/512/19579f/customs.png"
                    alt="customs"
                  />
                </Box>
              </Grid>
            </Grid>
          </PageSectionContainer>
          <StyledContainer>
            <StyledPaper>
              <Box marginY="3rem" position="relative">
                <Typography
                  paddingTop="2rem"
                  variant="body1"
                  gutterBottom
                  color={colors.title}
                  className="title-animation"
                  fontSize="15px !important"
                >
                  {t("customs.heading")}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={colors.title}
                  className="title-animation"
                  fontSize="40px !important"
                >
                  {t("customs.what_we_offer")}
                </Typography>
              </Box>
              <Box position="relative">
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    data-aos="fade-up"
                    data-aos-delay={50}
                    data-aos-offset="100"
                    data-aos-duration="600"
                  >
                    <ServiceOffer
                      heading={t("customs.export_import_processing")}
                      description={t("customs.export_import_processing_desc")}
                      Icon={ExitToAppIcon}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    data-aos="fade-up"
                    data-aos-delay={100}
                    data-aos-offset="100"
                    data-aos-duration="600"
                  >
                    <ServiceOffer
                      heading={t("customs.customs_clearance")}
                      description={t("customs.customs_clearance_desc")}
                      Icon={CheckBoxIcon}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    data-aos="fade-up"
                    data-aos-delay={150}
                    data-aos-offset="100"
                    data-aos-duration="600"
                  >
                    <ServiceOffer
                      heading={t("customs.certificates")}
                      description={t("customs.certificates_desc")}
                      Icon={Description}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    data-aos="fade-up"
                    data-aos-delay={200}
                    data-aos-offset="100"
                    data-aos-duration="600"
                  >
                    <ServiceOffer
                      heading={t("customs.customs_advice")}
                      description={t("customs.customs_advice_desc")}
                      Icon={EmojiObjectsIcon}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    data-aos="fade-up"
                    data-aos-delay={250}
                    data-aos-offset="100"
                    data-aos-duration="600"
                  >
                    <ServiceOffer
                      heading={t("customs.customs_abroad")}
                      description={t("customs.customs_abroad_desc")}
                      Icon={AssignmentIcon}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    data-aos="fade-up"
                    data-aos-delay={300}
                    data-aos-offset="100"
                    data-aos-duration="600"
                  >
                    <ServiceOffer
                      heading={t("customs.transshipment")}
                      description={t("customs.transshipment_desc")}
                      Icon={ShoppingCartCheckoutIcon}
                    />
                  </Grid>
                </Grid>
              </Box>
            </StyledPaper>
          </StyledContainer>
          <PageSectionContainer
            justifyContent="center"
            alignItems={"center"}
            marginBottom="1rem"
            marginTop="4rem"
            overflow="hidden"
          >
            <Grid container spacing={10} overflow="hidden">
              <Grid item md={6} xs={12} data-aos="fade-right">
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img
                    src={CustomsLocation}
                    alt="Warehouse"
                    style={{ maxWidth: "100%", borderRadius: "10px" }}
                  />

                  {/*
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54595.80376989626!2d16.340207010058922!3d48.093940244175826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476dae998f5a7ce1%3A0x40097572de633d0!2sBiedermannsdorf!5e0!3m2!1sde!2sat!4v1695915606569!5m2!1sde!2sat"
                    width="95%"
                    height="95%"
                    style={{
                      borderRadius: "20px",
                      border: `1px solid ${colors.font}`,
                    }}
                  />*/}
                </Box>
              </Grid>
              <Grid item md={6} xs={12} data-aos="fade-left">
                <Typography variant="h4" color={colors.font} gutterBottom>
                  {t("customs.customs_clearance_biedermannsdorf")}
                </Typography>
                <Typography variant="body1" color={colors.font}>
                  {t("customs.customs_clearance_biedermannsdorf_desc_p1")}
                </Typography>
                <br />
                <Typography variant="body1" color={colors.font}>
                  {t("customs.customs_clearance_biedermannsdorf_desc_p2")}
                </Typography>
                <br />
                <Typography variant="body1" color={colors.font}>
                  {t("customs.customs_clearance_biedermannsdorf_desc_p3")}
                </Typography>
                <br />
                <Typography variant="body2" color={colors.syracuse}>
                  {t("customs.contact_instruction")}
                </Typography>
              </Grid>
            </Grid>
          </PageSectionContainer>
        </Paper>
      </main>
    </>
  );
};

export default CustomsNew;
