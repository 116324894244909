import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

const detectorOptions = {
  order: ["localStorage", "cookie", "htmlTag", "path", "subdomain"],
  caches: ["localStorage", "cookie"],
};
export const supported_languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "de",
    name: "Deutsch",
    country_code: "de",
  },
  {
    code: "hr",
    name: "Hrvatski",
    country_code: "hr",
  },
  {
    code: "ro",
    name: "Română",
    country_code: "ro",
  },
  {
    code: "hu",
    name: "Magyar",
    country_code: "hu",
  },
  {
    code: "tr",
    name: "Türkçe",
    country_code: "tr",
  },
  {
    code: "it",
    name: "Italiano",
    country_code: "it",
  },
];
{
  /*
export const supported_languages = [
  {
    code: "bg",
    name: "Bulgarian",
    country_code: "bg",
  },
  {
    code: "hr",
    name: "Croatian",
    country_code: "hr",
  },
  {
    code: "cs",
    name: "Czech",
    country_code: "cz",
  },

  {
    code: "en",
    name: "English",
    country_code: "gb",
  },

  {
    code: "fi",
    name: "Finnish",
    country_code: "fi",
  },
  {
    code: "fr",
    name: "French",
    country_code: "fr",
  },
  {
    code: "de",
    name: "German",
    country_code: "de",
  },
  {
    code: "hi",
    name: "Hindi",
    country_code: "in",
  },

  {
    code: "it",
    name: "Italian",
    country_code: "it",
  },
  {
    code: "ja",
    name: "Japanese",
    country_code: "jp",
  },
  {
    code: "lt",
    name: "Lithuanian",
    country_code: "lt",
  },
  {
    code: "nl",
    name: "Dutch",
    country_code: "nl",
  },
  {
    code: "no",
    name: "Norwegian",
    country_code: "no",
  },
  {
    code: "pl",
    name: "Polish",
    country_code: "pl",
  },
  {
    code: "ro",
    name: "Romanian",
    country_code: "ro",
  },
  {
    code: "ru",
    name: "Russian",
    country_code: "ru",
  },
  {
    code: "sk",
    name: "Slovak",
    country_code: "sk",
  },
  {
    code: "sr",
    name: "Serbian",
    country_code: "rs",
  },
  {
    code: "es",
    name: "Spanish",
    country_code: "es",
  },
  {
    code: "sv",
    name: "Swedish",
    country_code: "se",
  },
  {
    code: "tr",
    name: "Turkish",
    country_code: "tr",
  },
  {
    code: "uk",
    name: "Ukrainian",
    country_code: "ua",
  },
  {
    code: "uz",
    name: "Uzbek",
    country_code: "uz",
  },
  {
    code: "zh",
    name: "Chinese",
    country_code: "cn",
  },
];
*/
}
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: [
      "en",
      "de",
      "bg",
      "cs",
      "es",
      "fi",
      "fr",
      "hi",
      "hr",
      "hu",
      "it",
      "ja",
      "lt",
      "nl",
      "no",
      "pl",
      "ro",
      "ru",
      "sk",
      "sr",
      "sv",
      "tr",
      "uk",
      "uz",
      "zh",
    ],
    fallbackLng: "en",
    detection: detectorOptions,
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
    initImmediate: false,
    react: {
      useSuspense: true,
    },
  });
