import React from "react";
import { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import styled from "styled-components";
import colors from "@/utils/colors";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import mmLogo from "@/assets/mmLogoSmallWhite.png";
import "@fontsource/roboto-mono";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { supported_languages } from "@/services/i18n";
import PopupMenu from "@/features/navbar/PopupMenu";

const Logo = styled.img`
  height: 5rem;
`;

const NavbarContainer = styled(Box)`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  padding: 0.5rem 1rem !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100% !important;

  @media (min-width: 600px) {
    max-width: 720px !important;
  }

  @media (min-width: 900px) {
    max-width: 1236px !important;
  }
`;

const LanguagePickerContainer = styled(Box)`
  display: flex;
  border-radius: 100%;
  padding: 1rem;
  overflow: hidden;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.5s;
  &:hover {
    background-color: rgba(34, 31, 32, 0.1) !important;
  }
`;

const StyledAppBar = styled(({ ...props }) => <AppBar {...props} />)`
  position: sticky;
  right: 0px;
  top: 0px;
  background-color: ${colors.font};
`;

const LinksContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LinkContainer = styled(Box)`
  margin: 0.5rem 1rem;
`;

const NavbarLink = styled(({ ...props }) => (
  <Typography variant="body1" {...props} />
))`
  font-size: 1rem;
  color: ${colors.white};
  font-weight: 500 !important;
`;

const Navbar = () => {
  const { t } = useTranslation();
  const [elevation, setElevation] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isMobile = useMediaQuery("(max-width: 901px)");
  const currentLanguage = supported_languages.find(
    (lang) => lang.code === i18next.language
  );

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (code: string) => {
    if (code) {
      i18next.changeLanguage(code);
    }
    handleClose();
  };

  const changeLanguage = (code: string) => {
    if (code) {
      i18next.changeLanguage(code);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      const newElevation = window.scrollY > 0 ? 1 : 0;
      setElevation(newElevation);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const location = useLocation();
  const isCoffe = location.pathname.indexOf("coffee") !== -1;

  return (
    <>
      <main>
        <Paper sx={{ maxHeight: "15vh" }} elevation={0}>
          <StyledAppBar
            elevation={elevation}
            sx={{ backgroundColor: isCoffe ? colors.coffenav : colors.font }}
          >
            <NavbarContainer>
              <Link to="/">
                <Box display="flex" alignItems="center" flexDirection="row">
                  <Logo className="logo" src={mmLogo} alt="logo" />
                  <Typography marginLeft="0.5rem" color={colors.white}>
                    <b>GROUP</b>
                  </Typography>
                </Box>
              </Link>
              <Box
                display="flex"
                className="responsive-navbar"
                flexDirection="column"
              >
                <LinksContainer className="display-navbar">
                  <LinkContainer>
                    <Link to="/">
                      <NavbarLink className={"nav-link"}>
                        {t("navbar.home")}
                      </NavbarLink>
                    </Link>
                  </LinkContainer>
                  <LinkContainer>
                    <Link to="/about">
                      <NavbarLink className={"nav-link"}>
                        {t("navbar.about")}
                      </NavbarLink>
                    </Link>
                  </LinkContainer>
                  <LinkContainer>
                    <Link to="/services">
                      <NavbarLink className={"nav-link"}>
                        {t("navbar.service")}
                      </NavbarLink>
                    </Link>
                  </LinkContainer>
                  {/* <LinkContainer>
                    <Link underline="none" href="/shopping">
                      <NavbarLink className={"nav-link"}>
                        {t("navbar.shopping")}
                      </NavbarLink>
                    </Link>
                  </LinkContainer> */}
                  <LinkContainer>
                    <Link to="/logistics">
                      <NavbarLink className={"nav-link"}>
                        {t("navbar.logistics")}
                      </NavbarLink>
                    </Link>
                  </LinkContainer>
                  <LinkContainer>
                    <Link to="/customs">
                      <NavbarLink className={"nav-link"}>
                        {t("navbar.customs")}
                      </NavbarLink>
                    </Link>
                  </LinkContainer>
                  <LinkContainer>
                    <Link to="/freight">
                      <NavbarLink className={"nav-link"}>
                        {t("navbar.freight")}
                      </NavbarLink>
                    </Link>
                  </LinkContainer>
                  <LinkContainer>
                    <Link to="/pricelist">
                      <NavbarLink className={"nav-link"}>
                        {t("navbar.pricelist")}
                      </NavbarLink>
                    </Link>
                  </LinkContainer>
                </LinksContainer>
                {/* Conditional rendering based on screen width */}
                {isMobile ? (
                  // Show this on mobile screens (below 600px)
                  <LinkContainer className="center-text">
                    <Typography color={colors.white}>
                      {currentLanguage?.name}
                    </Typography>
                    <LanguagePickerContainer onClick={handleMenuOpen}>
                      {
                        <div>
                          <span
                            className={`fi fi-${supported_languages.find(
                              (lang) => lang.code === i18next.language
                            )?.country_code}`}
                          />
                        </div>
                      }
                    </LanguagePickerContainer>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      {supported_languages.map(
                        ({ code, name, country_code }) => (
                          <MenuItem
                            key={code}
                            onClick={() => handleMenuClose(code)}
                          >
                            <Box marginRight={"0.5rem"}>
                              <span className={`fi fi-${country_code}`} />
                            </Box>
                            {name}
                          </MenuItem>
                        )
                      )}
                    </Menu>
                  </LinkContainer>
                ) : (
                  // Show this on larger screens (above 600px)
                  <Box
                    display="flex"
                    justifyContent="space-evenly"
                    paddingTop="4px"
                  >
                    {supported_languages.map(({ code, name, country_code }) => (
                      <Box
                        sx={{ cursor: "pointer" }}
                        marginRight={"0.5rem"}
                        onClick={() => changeLanguage(code)}
                      >
                        <span
                          style={{
                            borderRadius: "3px",
                            border: "solid 0.1px white",
                          }}
                          className={`fi fi-${country_code}`}
                        />
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
              <Box>
                <PopupMenu></PopupMenu>
              </Box>
            </NavbarContainer>
          </StyledAppBar>
          <Toolbar />
        </Paper>
      </main>
    </>
  );
};

export default Navbar;
