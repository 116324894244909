import colors from "@/utils/colors";
import { SvgIconComponent } from "@mui/icons-material";
import { Box, Paper, Typography } from "@mui/material";
import styled from "styled-components";

const LogisticsOfferPaper = styled(({ ...props }) => (
  <Paper elevation={0} {...props} />
))`
  border-radius: 20px !important;
  border: 2px solid ${colors.primary};
  margin: 2rem;
  height: 80%;
`;

const ServiceOffer = ({
  heading,
  description,
  Icon,
}: {
  heading: string;
  description: string;
  Icon: SvgIconComponent;
}) => {
  const StyledIcon = styled(({ ...props }) => <Icon {...props} />)`
    font-size: 60px !important;
    margin: 1rem 0 1rem;
    color: ${colors.button};
  `;
  return (
    <LogisticsOfferPaper>
      <Box
        boxSizing="border-box"
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        padding="1rem"
      >
        <Box width="100%" display="flex" justifyContent="center">
          <StyledIcon />
        </Box>
        <Typography
          variant="h4"
          align="center"
          color={colors.button}
          gutterBottom
          paddingBottom="1rem"
        >
          {heading}
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color={colors.font}
          gutterBottom
        >
          {description}
        </Typography>
      </Box>
    </LogisticsOfferPaper>
  );
};

export default ServiceOffer;
