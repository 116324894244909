import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";
import colors from "@/utils/colors";
import PageSectionContainer from "@/styles/PageSectionContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ScrollToTop } from "@/utils/helpers";

export const StyledContainer = styled.div`
  animation: dipFromBlack 1.5s;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    background-color: ${colors.secondary};
    min-width: 100vw;
    top: 5%;
    height: 90%;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
`;

export const StyledPaper = styled(({ ...props }) => (
  <Paper elevation={2} {...props} />
))`
  animation: dipFromBlack 0.5s;

  background-color: ${colors.white};
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px !important;
  position: relative;
  z-index: 1;
  @media (min-width: 600px) {
    max-width: 720px;
  }
  @media (min-width: 900px) {
    max-width: 1236px;
  }
`;
export const StyledImage = styled.img`
  width: 350px;
  max-height: 300px;
  padding-top: 3rem;
`;

export const StyledButton = styled(({ ...props }) => <Button {...props} />)`
  background-color: ${colors.button} !important;
  color: ${colors.white} !important;
  margin-top: 1.5rem !important;
  text-transform: none !important;
  font-size: 1.2rem !important;
  width: 250px;
  height: 48px;
  &:hover {
    background-color: ${colors.button_hover} !important;
  }
  @media (max-width: 900px) {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
`;

function createData(service: string, price: number | string) {
  return { service, price };
}

const Pricelist = () => {
  const { t } = useTranslation();
  const isMediumScreen = useMediaQuery("(min-width:900px)");

  const rows = [
    createData(t("pricelist.unloading_1"), 30.0),
    createData(t("pricelist.unloading_2"), 80.0),
    createData(t("pricelist.unloading_3"), 170.0),
    createData(t("pricelist.unloading_4"), 250.0),
    createData(t("pricelist.loading_1"), 30.0),
    createData(t("pricelist.loading_2"), 80.0),
    createData(t("pricelist.loading_3"), 250.0),
    createData(t("pricelist.loading_4"), 280.0),
    createData(t("pricelist.on_hold"), 50.0),
    createData(t("pricelist.other_warehouse"), 40.0),
    createData(
      t("pricelist.warehouse_insurance"),
      0.11 + "% " + t("pricelist.freight")
    ),
    createData(
      t("pricelist.hazardous_goods_insurance"),
      0.22 + "% " + " " + t("pricelist.freight")
    ),
    createData(t("pricelist.consignment_note"), 25.0),
    createData(t("pricelist.inspection_report"), 30.0),
    createData(
      t("pricelist.transport_insurance"),
      80 + " (" + t("pricelist.minimum") + ")"
    ),
    createData(t("pricelist.storage"), 2.5),
  ];

  return (
    <>
      <main>
        <ScrollToTop />
        <Paper
          sx={{
            minHeight: "100vh",
            backgroundColor: colors.primary,
            position: "relative",
            zIndex: "1",
          }}
          elevation={0}
        >
          <PageSectionContainer
            marginTop="3rem"
            marginBottom="3rem"
            position="relative"
          >
            <Grid container spacing={4} position="relative">
              <Grid
                item
                xs={12}
                md={7}
                display="flex"
                flexDirection="column"
                alignItems={isMediumScreen ? "left" : "center"}
                data-aos="fade-down"
              >
                <Typography
                  variant="subtitle1"
                  color={colors.title}
                  align={isMediumScreen ? "left" : "center"}
                  className="title-animation"
                  fontSize="50px !important"
                  lineHeight={1.4}
                >
                  {t("pricelist.title")}
                </Typography>
                <Typography
                  maxWidth={isMediumScreen ? "80%" : "100%"}
                  variant="subtitle1"
                  color={colors.title}
                  align={isMediumScreen ? "left" : "center"}
                  className="title-animation"
                  fontSize="30px !important"
                  lineHeight={1.8}
                >
                  {t("pricelist.description")}
                </Typography>
                <Link to="/contact">
                  <StyledButton variant="contained">
                    {t("pricelist.contact_us")}
                  </StyledButton>
                </Link>
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box data-aos="fade-down">
                  <StyledImage
                    src="https://img.icons8.com/pastel-glyph/512/19579f/lowest-price--v2.png"
                    alt="lowest prices for the best deals"
                  />
                </Box>
              </Grid>
            </Grid>
          </PageSectionContainer>
          {/*
          <StyledContainer>
            <StyledPaper>
              <Box marginTop="3rem" position="relative">
                <Typography
                  paddingTop="2rem"
                  variant="body1"
                  gutterBottom
                  color={colors.title}
                  className="title-animation"
                  fontSize="15px !important"
                >
                  {t("pricelist.heading")}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={colors.title}
                  className="title-animation"
                  fontSize="40px !important"
                >
                  {t("pricelist.subheading")}
                </Typography>
              </Box>
              <Box
                marginBottom="3rem"
                position="relative"
                display="flex"
                justifyContent="center"
              >
                <Box
                  width="70%"
                  sx={{
                    position: "relative",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: "10%",
                      bottom: "10%",
                      left: "-10%",
                      borderLeft: "1px solid #19579f",
                    },
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      top: "10%",
                      bottom: "10%",
                      right: "-10%",
                      borderRight: `1px solid ${colors.button}`,
                    },
                  }}
                >
                  <TableContainer
                    component={Paper}
                    elevation={0}
                    sx={{
                      border: "none",
                      paddingTop: "2rem",
                      paddingBottom: "2rem",
                    }}
                  >
                    <Table
                      sx={{ minWidth: 650, border: "none" }}
                      aria-label="caption table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: "bold" }}
                            >
                              {t("pricelist.service")}
                            </Typography>
                          </TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right">
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: "bold" }}
                            >
                              {t("pricelist.price")}&nbsp;(€)
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.service}>
                            <TableCell component="th" scope="row">
                              {row.service}
                            </TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right">{row.price}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </StyledPaper>
          </StyledContainer>*/}
          <PageSectionContainer
            justifyContent="center"
            alignItems={"center"}
            marginBottom="1rem"
            overflow="hidden"
          >
            <Box></Box>
          </PageSectionContainer>
        </Paper>
      </main>
    </>
  );
};

export default Pricelist;
